import React, { useState } from 'react';
import cv from '../images/cv fullstack.pdf';

const CVViewer = () => {
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);

  const handleDownload = () => {
    window.open(cv);
  };

  const togglePhoneNumber = () => {
    setShowPhoneNumber(!showPhoneNumber);
  };

  return (
    <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
      <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-yellow-200">Mon CV</h2>
      

      <div className="flex justify-center mt-8">
        <button onClick={handleDownload} className="mr-4 py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">
          Voir le CV
        </button>
   
      </div>


    </div>
  );
};

export default CVViewer;
