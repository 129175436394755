import React from 'react';
import { useSpring, animated } from 'react-spring';

const TrainAnimation = () => {
  const props = useSpring({
    from: { left: '-20%', transform: 'translateX(-20%)' },
    to: { left: '120%', transform: 'translateX(120%)' },
    config: { duration: 5000, loop: true },
  });

  return (
    <animated.div
      style={{
        width: '100%',
        height: '20px',
        backgroundColor: 'red',
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
        zIndex: -1,
        ...props,
      }}
    />
  );
}

export default TrainAnimation;
