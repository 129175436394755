import React from 'react';
import { motion } from 'framer-motion';
import { useSpring, animated } from 'react-spring';
import TrainAnimation from './train';
import Particles from 'react-tsparticles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHtml5, faCss3Alt, faJs, faReact, faNodeJs, faPython, faPhp } from '@fortawesome/free-brands-svg-icons';

const YellowCard = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -50, scale: 0.8 }}
      animate={{ opacity: 1, y: 0, scale: 1 }} 
      transition={{ duration: 0.5, type: 'spring', stiffness: 120, damping: 10 }}
      whileHover={{ scale: 1.1 }} 
      className="bg-yellow-500 p-8 rounded-lg shadow-md"
    >
      {children}
    </motion.div>
  );
}


const CardTwo = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -50, scale: 0.8 }} 
      animate={{ opacity: 1, y: 0, scale: 1 }}
      transition={{ duration: 0.5, type: 'spring', stiffness: 120, damping: 10 }}
      className="bg-yellow-500 p-8 rounded-lg shadow-md"
    >
      {children}
    </motion.div>
  );
}
const AboutMe = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8 mt-10">
      
      <YellowCard>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex justify-center">
            <img className="rounded-full w-40 h-44 object-cover" src="1.png" alt="Votre photo" />
          </div>
          <div>
            <h2 className="text-3xl font-bold text-gray-800 mb-4">Welcome</h2>
            <p className="text-lg text-gray-600">
      Je me présente, EL KATI Anis, développeur Web full stack.<br />
      Passionné par l’écosystème du Web, j’assure des veilles technologique très fréquemment de manière à me tenir toujours aux normes des nouvelles technologies.<br />
      J’ai à cœur de participer à l’accomplissement et la réussite de la transformation de vos idées dans le domaine du numérique.<br /><br />
      À très bientôt,<br />
      Anis
    </p>
          </div>
        </div>

        <div className="grid grid-cols-7 gap-4 mt-8">
          <motion.div
            whileHover={{ scale: 1.2 }}
            className="text-3xl text-gray-600"
          >
            <FontAwesomeIcon icon={faHtml5} />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.2 }}
            className="text-3xl text-gray-600"
          >
            <FontAwesomeIcon icon={faCss3Alt} />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.2 }}
            className="text-3xl text-gray-600"
          >
            <FontAwesomeIcon icon={faJs} />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.2 }}
            className="text-3xl text-gray-600"
          >
            <FontAwesomeIcon icon={faReact} />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.2 }}
            className="text-3xl text-gray-600"
          >
            <FontAwesomeIcon icon={faNodeJs} />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.2 }}
            className="text-3xl text-gray-600"
          >
            <FontAwesomeIcon icon={faPython} />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.2 }}
            className="text-3xl text-gray-600"
          >
            <FontAwesomeIcon icon={faPhp} />
          </motion.div>
        </div>
      </YellowCard>


    </div>
    
  );
}

export default AboutMe;
