import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar';
import AboutMe from './components/moi';
import Footer from './components/footer';
import Presentation from './components/presentation';
import Projects from './components/work';
import ContactForm from './components/Contact';
import CVViewer from './components/cv.js';
import ThemeSelector from './components/theme.js';


const App = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<AboutMe />} />
          <Route path="/work" element={<Projects />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/cv" element={<CVViewer />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
