import React from 'react';
import styled from 'styled-components';
import { FaGithub, FaLinkedin, FaFilePdf } from 'react-icons/fa';
import cv from '../images/cv fullstack.pdf';

const StyledFooter = styled.footer`
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px; /* Ajout d'un espace en haut */
`;

const Footer = () => {
  return (
    <StyledFooter>
      <FooterContent>
        <a className='mr-16' href="https://github.com/AnisElkt" target="_blank" rel="noopener noreferrer">
          <FaGithub size={48} />
        </a>
        <a className='mr-16'  href="https://www.linkedin.com/in/anis-el-kati-51499a2ba/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin size={48} />
        </a>
        <a  href='cv' target="_blank" rel="noopener noreferrer">
          <FaFilePdf size={48} />
        </a>
      </FooterContent>
    </StyledFooter>
  );
}

export default Footer;
