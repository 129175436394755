import React, { useState } from 'react';
import { motion } from 'framer-motion';
import puissance4 from '../images/p4.png';
import videopuissance4 from "../images/puissance4video.mov"; 
import twitter from '../images/twittder.png';
import videotwitter from '../images/videotwitter.mp4';
import spotify from '../images/spot.png';
import videospotify from '../images/spotify.mov';
import "animate.css/animate.min.css";
import maquette from '../images/maquette.png';
import videomaquette from '../images/videomaquette.mov'
const Projects = () => {
  const projects = [
    {
      title: "Puissance 4",
      description: "Réalisé en Javascript orienté objet(POO), HTML et CSS",
      demoLink: "#",
      videoLink: videopuissance4, 
      codeLink: "https://github.com/AnisElkt/Puissance-4",
      image: puissance4
    },
    {
      title: "Twitter",
      description: "Projet en groupe de 4, réalisé en Javascript, PHP, Tailwind, Ajax, Mysql, avec de nombreuses fonctionnalitées tel que l'inscription/connexion, des tweets avec hashtag , un profil editable, une recherche utilisateurs avec système d'abonnement.",
      demoLink: "#",
      videoLink: videotwitter,
      codeLink: "https://github.com/AnisElkt/My-twitter",
      image: twitter
    },
    {
      title: "Spotify",
      description: "Projet en groupe de 4,réalisé en react, javascript, API, le but était de réaliser un spotify en récupérant des données API d'une base de donnée et pouvoir refaire spotify avec des artistes et des musiques",
      demoLink: "#",
      videoLink: videospotify, 
      codeLink: "https://github.com/user/project2",
      image: spotify
    },
    {
        title: "Maquette Responsive",
        description: "Projet individuel , réaliser en Html et CSS, le but était de réaliser une maquette et la rendre 100% responsive en css pure",
        demoLink: "#",
        videoLink: videomaquette, 
        codeLink: "https://github.com/AnisElkt/Maquette-responsive-full-css",
        image: maquette
      },
  ];

  const [showVideo, setShowVideo] = useState(false);
  const [currentVideo, setCurrentVideo] = useState('');

  const handleDemoClick = (videoLink) => {
    setCurrentVideo(videoLink);
    setShowVideo(true);
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
    setCurrentVideo('');
  };

  return (
    <div>
      <motion.h2 
        className="text-3xl font-bold mb-4 text-center text-yellow-500 animate__animated animate__backInLeft"
      >
        Mes projets
      </motion.h2>    
      <p className="text-gray-600 mb-8 text-center">Je vous laisse prendre connaissance des démos ainsi que du code partagé sur github d’une partie des projets que j’ai pu mener.</p>

      <div className="grid grid-cols-2 gap-4">
        {projects.map((project, index) => (
          <motion.div 
            key={index} 
            className="border rounded p-4 animate__animated animate__backInRight"
            initial={{ opacity: 0, y: 20 }} 
            animate={{ opacity: 1, y: 0 }} 
            transition={{ duration: 0.5, delay: index * 0.1 }} 
          >
            <img src={project.image} alt={project.title} className="w-full h-72 object-cover mb-2" />
            <h3 className="text-lg text-yellow-300 font-semibold mb-2">{project.title}</h3>
            <p className="text-gray-600 mb-2">{project.description}</p>
            <div className="flex justify-between">
              <a href={project.demoLink} className="text-blue-500 hover:underline" onClick={() => handleDemoClick(project.videoLink)}>Voir</a>
              <a href={project.codeLink} className="text-blue-500 hover:underline">Github</a>
            </div>
          </motion.div>
        ))}
      </div>
      
      {showVideo && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
          <div className="relative max-w-screen-lg w-full">
            <button onClick={handleCloseVideo} className="absolute  top-0 right-0 m-4 text-red-500 text-3xl">X</button>
            <video src={currentVideo} className="w-full" controls autoPlay></video>
          </div>
        </div>
      )}
    </div>
  );
}

export default Projects;
