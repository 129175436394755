import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const ContactForm = () => {
  const navigate = useNavigate();

  const handleNavigation = (e) => {
    e.preventDefault();
    const contactForm = document.querySelector('.animate__zoomIn');
    contactForm.classList.add('animate__zoomOut');
    setTimeout(() => {
      navigate('/work');
    }, 500); 
  };

  const handleWorkNavigation = (e) => {
    e.preventDefault();
    const contactForm = document.querySelector('.animate__zoomIn');
    contactForm.classList.add('animate__zoomOut');
    setTimeout(() => {
      navigate('/work');
    }, 500); 
  };

  return (
    <div className="animate__animated animate__zoomIn">
      <section className="bg-yellow-200 dark:bg-yellow-500">
        
        <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <Link to="/work" onClick={handleWorkNavigation} className="block text-center text-yellow-600 dark:text-yellow-300 hover:text-yellow-800 dark:hover:text-yellow-500 mt-4 pulse">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 17l-5-5m0 0l5-5m-5 5h12" />
              </svg>
              <span className="ml-1 text-3xl text-black">My Work </span>
            </Link>
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-yellow-200">Contactez-moi</h2>
          
          <p className="mb-8 lg:mb-16 font-light text-center text-gray-900 dark:text-gray-700 sm:text-xl">Je reste à votre disposition pour toutes questions, ou informations complémentaires</p>
          <form action="https://submit-form.com/BBjMhWYoS" method='post' className="space-y-8">
            <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700">Votre adresse email</label>
              <input type="email" id="email" className="shadow-sm bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-500 block w-full p-2.5 dark:bg-yellow-200 dark:border-yellow-600 dark:placeholder-black dark:text-black dark:shadow-sm-light" placeholder="" required />
            </div>
            <div>
              <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700">Sujet</label>
              <input type="text" id="subject" className="block p-3 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 dark:bg-yellow-200 dark:border-yellow-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-yellow-500 dark:focus:border-yellow-500 dark:shadow-sm-light" placeholder="" required />
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700">Votre message</label>
              <textarea id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg shadow-sm border border-gray-300 focus:ring-yellow-500 focus:border-yellow-500 dark:bg-yellow-200 dark:border-yellow-600 dark:placeholder-yellow-400 dark:text-black dark:focus:ring-yellow-500 dark:focus:border-yellow-500" placeholder="Laissez un commentaire..."></textarea>
            </div>
          
            <button type="submit" className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-yellow-500 sm:w-fit hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:focus:ring-yellow-800">Envoyer le message</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ContactForm;
